#whatsapp {
    position: fixed;
    right: 50px;
    bottom: 50px;
    z-index: -1;

    display: grid;
    place-items: center;

    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #29A71A;
    box-shadow: 0px 4px 20px 0px #00000040;

    transition: all .3s ease-in-out;
    opacity: 0;

    @media (max-width: 992px) {
        right: 30px;
        bottom: 40px;

        width: 50px;
        height: 50px;
    }
    &:hover {
        img, svg {
            translate: 0 -5px;
        }
    }
    img, svg {
        width: 38px;
        aspect-ratio: 1;
        transition: all .3s ease-in-out;

        @media (max-width: 992px) {
            width: 30px;
        }
    }
}