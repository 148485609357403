* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
}
a:focus, a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*------------- Hover background gradient --------------*/
@font-face {
  font-family: "Autography";
  src: url("../fonts/Autography.otf") format("opentype");
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-BoldOblique.otf") format("opentype");
  font-weight: bold;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-DemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-DemiBoldOblique.otf") format("opentype");
  font-weight: 600;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-ExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-ExtraBoldOblique.otf") format("opentype");
  font-weight: 800;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-Heavy.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-HeavyOblique.otf") format("opentype");
  font-weight: 900;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-LightOblique.otf") format("opentype");
  font-weight: 300;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-MediumOblique.otf") format("opentype");
  font-weight: 500;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-RegularOblique.otf") format("opentype");
  font-weight: 400;
  font-style: oblique;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-Thin.otf") format("opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Visby";
  src: url("../fonts/VisbyCF/VisbyCF-ThinOblique.otf") format("opentype");
  font-weight: 100;
  font-style: oblique;
  font-display: swap;
}
* {
  font-family: "Visby";
}

.form-group {
  position: relative;
  margin: 0;
}

input.error, textarea.error, select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

/*------------- Hover background gradient --------------*/
.navbar {
  background-color: #fff;
  padding-block: 21px 20px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}
@media (max-width: 992px) {
  .navbar {
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1490196078);
    padding-block: 24px 19px;
  }
}
.navbar-brand img {
  max-width: 302px;
  max-height: 58px;
}
@media (max-width: 1200px) {
  .navbar-brand img {
    max-width: 250px;
  }
}
@media (max-width: 992px) {
  .navbar-brand img {
    max-width: 180px;
  }
}
.navbar-toggler {
  padding: 7px 8px 3px 10px;
  background-color: #808000;
  border-radius: 20px 0 0 0;
  border: none;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
.navbar-nav {
  gap: 63px;
}
@media (max-width: 1400px) {
  .navbar-nav {
    gap: 45px;
  }
}
@media (max-width: 1200px) {
  .navbar-nav {
    gap: 40px;
  }
}
@media (max-width: 992px) {
  .navbar-nav {
    gap: 30px;
  }
}
.navbar-nav .nav-link {
  position: relative;
  color: #121212;
  font-size: 16px;
  font-weight: 700;
  line-height: 24.96px;
  padding: 0 !important;
}
@media (max-width: 992px) {
  .navbar-nav .nav-link {
    color: #7E8400;
    line-height: 18px;
  }
}
@media (max-width: 992px) {
  .navbar-nav .nav-link:hover, .navbar-nav .nav-link.active {
    color: #121212;
  }
}
.navbar-nav .nav-link:hover::before, .navbar-nav .nav-link.active::before {
  opacity: 1;
}
.navbar-nav .nav-link::before {
  content: "";
  width: 15px;
  aspect-ratio: 1;
  background-color: #7E8400;
  border-radius: 50%;
  transition: all 0.4s ease;
  position: absolute;
  top: calc(50% - 7.5px);
  right: calc(100% + 18px);
  opacity: 0;
}
@media (max-width: 1400px) {
  .navbar-nav .nav-link::before {
    right: calc(100% + 10px);
  }
}
.navbar .main-btn {
  background-color: #6F7500;
}
@media (max-width: 1200px) {
  .navbar .main-btn {
    padding-inline: 32px;
  }
}
@media (max-width: 992px) {
  .navbar .main-btn {
    margin-top: 46px;
    max-width: 100%;
  }
}
.navbar .offcanvas {
  --bs-offcanvas-width: 100%;
}
.navbar .offcanvas-header {
  padding-block: 21px 20px;
  padding-inline: 0;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1490196078);
}
.navbar .offcanvas-body {
  padding: 50px 38px;
}
.navbar .offcanvas-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  gap: 36px;
  background-color: #808000;
  padding: 23px 38px;
}
.navbar .offcanvas-footer p {
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.05em;
}

#footer {
  padding-block: 131px 50px;
  position: relative;
}
@media (max-width: 992px) {
  #footer {
    padding-block: 55px 92px;
  }
}
#footer .navigation, #footer .redes {
  padding-top: 17px;
}
@media (max-width: 992px) {
  #footer .navigation, #footer .redes {
    padding-top: 0;
  }
}
#footer .navigation {
  gap: 29px;
}
#footer .navigation a {
  color: #121212;
  font-size: 16px;
  font-weight: 700;
  line-height: 24.96px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}
#footer .navigation a:hover {
  color: #7E8400;
}
@media (max-width: 992px) {
  #footer .logo {
    margin-bottom: 19px;
  }
}
#footer .logo p {
  color: #1E1E1E;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
@media (max-width: 992px) {
  #footer .logo p {
    position: absolute;
    bottom: 34px;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 30px;
  }
}
@media (max-width: 992px) {
  #footer .logo .weecom {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
  }
}
#footer .redes {
  gap: 37px;
}
@media (max-width: 992px) {
  #footer .redes {
    gap: 9px;
    flex-direction: column;
  }
}
#footer .redes p {
  color: #121212;
  font-size: 16px;
  font-weight: 700;
  line-height: 24.96px;
}
@media (max-width: 992px) {
  #footer .redes p {
    font-size: 14px;
    line-height: 21px;
  }
}

/*------------- Hover background gradient --------------*/
/*------------------------- Containers -----------------------*/
.termsfeed-com---palette-dark.termsfeed-com---nb {
  background: #fff;
  background-color: #fff;
  box-shadow: 0px 3px 10px #1E1E1E;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  box-shadow: 0px 3px 10px #1E1E1E;
}

.termsfeed-com---palette-light.termsfeed-com---nb {
  background-color: white;
}

.termsfeed-com---pc-dialog .cc-pc-head {
  background: #6F7500;
  color: #121212;
  border: #6F7500;
}
.termsfeed-com---pc-dialog .cc-pc-head .cc-pc-head-close:focus {
  border: 2px solid #fff;
}

.termsfeed-com---palette-dark .cc-cp-body,
.termsfeed-com---palette-dark .cc-cp-body-tabs,
.termsfeed-com---palette-dark .cc-cp-body-content,
.termsfeed-com---palette-dark .cc-cp-foot {
  background: #6F7500 !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs-item {
  background: #6F7500 !important;
  border: none !important;
  color: #fff !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs-item[active=true] {
  background: #121212 !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link {
  color: #fff !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs-item-link:focus {
  box-shadow: none !important;
}
.termsfeed-com---palette-dark .cc-cp-body-tabs {
  border-right: 1px solid #fff;
}
@media (max-width: 600px) {
  .termsfeed-com---palette-dark .cc-cp-body-tabs {
    border-right: none;
    border-bottom: 1px solid #fff;
  }
}
.termsfeed-com---palette-dark .cc-cp-foot {
  border-color: #fff !important;
}
.termsfeed-com---palette-dark .cc-cp-foot-byline a {
  color: #fff !important;
  font-weight: bold;
}
.termsfeed-com---palette-dark .cc-cp-foot-save {
  color: #6F7500;
  background: #fff;
  margin-right: 1rem !important;
  border: 1px solid #fff;
}

/*-------------------------TEXTS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-title {
  color: #121212;
}

.termsfeed-com---palette-dark .cc-nb-text {
  color: #121212;
}

/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree {
  background: #6F7500;
  color: #fff;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border-radius: 10px 0 0 0 !important;
}

.termsfeed-com---palette-dark .cc-nb-reject {
  color: #fff;
  background: #6F7500;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border-radius: 10px 0 0 0 !important;
}

.termsfeed-com---palette-dark .cc-nb-changep {
  color: #fff;
  background: #6F7500;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border-radius: 10px 0 0 0 !important;
}

.termsfeed-com---palette-dark .cc-nb-okagree {
  color: #fff;
  background: #6F7500;
  margin-right: 1rem !important;
  transition: background-color 1s;
  border-radius: 10px 0 0 0 !important;
}

.termsfeed-com---nb-simple {
  bottom: 2vh;
  transition: 0.4s;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.termsfeed-com---nb-simple::after {
  content: "";
  height: 3px;
  background-color: #6F7500;
  position: absolute;
  left: 0;
  bottom: 0;
}

.termsfeed-com---palette-light .cc-nb-okagree {
  border-radius: 10px 0 0 0 !important;
  padding: 10px 20px !important;
}

.termsfeed-com---palette-light .cc-nb-reject {
  border-radius: 10px 0 0 0 !important;
  padding: 10px 20px !important;
}

.termsfeed-com---palette-light .cc-nb-changep {
  border-radius: 10px 0 0 0 !important;
  padding: 10px 20px !important;
}

/*------------- Hover background gradient --------------*/
.main-btn {
  padding: 14px 54px 11px;
  border-radius: 20px 0 0 0;
  background-color: #7E8400;
  color: #fff;
  font-family: "Visby";
  font-size: 18px;
  font-weight: 800;
  line-height: 28.08px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.main-btn:hover {
  translate: 0 -3px;
  color: #fff;
}

#whatsapp {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: -1;
  display: grid;
  place-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #29A71A;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2509803922);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}
@media (max-width: 992px) {
  #whatsapp {
    right: 30px;
    bottom: 40px;
    width: 50px;
    height: 50px;
  }
}
#whatsapp:hover img, #whatsapp:hover svg {
  translate: 0 -5px;
}
#whatsapp img, #whatsapp svg {
  width: 38px;
  aspect-ratio: 1;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 992px) {
  #whatsapp img, #whatsapp svg {
    width: 30px;
  }
}

/*------------- Hover background gradient --------------*/
.scroll {
  width: 100%;
}

.text-marquee {
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  overflow-y: clip;
  z-index: 3;
}
.text-marquee .text-single {
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  will-change: transform;
  overflow: hidden;
}
.text-marquee .text-single.img-wrapper .text {
  margin-right: 80px;
}
@media (max-width: 992px) {
  .text-marquee .text-single.img-wrapper .text {
    margin-right: 57px;
  }
}
.text-marquee .text-single.img-wrapper .text img {
  max-width: 248px;
  object-fit: contain;
}
@media (max-width: 992px) {
  .text-marquee .text-single.img-wrapper .text img {
    max-width: 155px;
  }
}
.text-marquee .text {
  padding: 0;
  display: inline-block;
  margin-right: 100px;
}
@media (max-width: 992px) {
  .text-marquee .text {
    margin-right: 60px;
  }
}
.text-marquee .text, .text-marquee .text h3 {
  color: #808000;
  font-size: 90px;
  font-weight: 600;
  line-height: 100px;
  letter-spacing: -0.02em;
}
@media (max-width: 992px) {
  .text-marquee .text, .text-marquee .text h3 {
    font-size: 50px;
    line-height: 80px;
  }
}
.text-marquee .text i > strong {
  color: #121212;
  font-size: 220px;
  line-height: 100px;
}
@media (max-width: 992px) {
  .text-marquee .text i > strong {
    font-size: 80px;
    line-height: 1;
  }
}
@media (max-width: 599px) {
  .text-marquee .text {
    font-size: 5rem;
    line-height: 1.35;
  }
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.show--error input {
  color: red !important;
  border: 1px solid red !important;
}
.show--error input::placeholder {
  color: red !important;
}
.show--error textarea {
  color: red !important;
  border: 1px solid red !important;
}
.show--error textarea::placeholder {
  color: red !important;
}
.show--error select {
  color: red !important;
  border: 1px solid red !important;
}
.show--error select::placeholder {
  color: red !important;
}
.show--error .checkmark {
  color: red !important;
  border: 1px solid red !important;
}
.show--error .checkmark::placeholder {
  color: red !important;
}
.show--error .error--message {
  display: block;
  color: red !important;
}

.error--message {
  display: none;
}

.message-error-forms {
  display: none !important;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: #000000;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.error-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: #000000;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.error-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 30px 50px;
  gap: 20px;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.success-message-forms--container #btn--close-success-message ion-icon {
  font-size: 3.4rem;
  color: #000000;
}
.success-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.success-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: #000000;
  font-weight: 400;
  font-family: "";
  text-align: center;
}
.success-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .success-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .success-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .success-message-forms--container #btn--close-success-message img {
    width: 20px;
  }
  .success-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .success-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .success-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
  clip-path: polygon(0 8%, 72% 8%, 75% 0, 100% 0, 100% 100%, 19% 100%, 16% 92%, 0 92%);
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, #000000);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}

#page404 .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
}
#page404 .title .sad {
  transform: rotate(90deg);
  font-size: 60px;
}

/*
    SUMMARY
        0 - Set Transitions
        1 - Hovers
        2 - Enter Animations
        3 - Keyframes

*/
img.zoom--hover,
img.up--hover,
img.arrow--hover {
  transition: 0.4s;
}

img.zoom--hover:hover {
  transform: scale(1.05);
}

img.up--hover:hover {
  transform: translateY(-30px);
}

.double {
  display: grid !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.double__img {
  grid-area: 1/1/-1/-1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.enter-image-style {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
}

a:hover img.arrow--hover,
button:hover img.arrow--hover {
  transform: translateX(10px);
}

.animated-element[data-animation] {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

.scroll-down-animation {
  animation-name: scrollDown;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

[data-animation] {
  opacity: 0 !important;
  transition: 1s;
}

[data-animation=right] {
  transform: translateX(-50px);
}

[data-animation=left] {
  transform: translateX(50px);
}
@media (max-width: 999px) {
  [data-animation=left] {
    transform: translateX(-50px) !important;
  }
}

[data-animation=up] {
  transform: translateY(50px);
}

[data-animation=down] {
  transform: translateY(-50px);
}

[data-sequencial] [data-sequencial-stagger] {
  opacity: 0;
}
[data-sequencial] [data-sequencial-stagger=right] {
  transform: translateX(-50px);
}
[data-sequencial] [data-sequencial-stagger=left] {
  transform: translateX(50px);
}
[data-sequencial] [data-sequencial-stagger=up] {
  transform: translateY(50px);
}
[data-sequencial] [data-sequencial-stagger=down] {
  transform: translateY(50px);
}

[data-animation-banner] {
  opacity: 0 !important;
  transition: 1s;
}
[data-animation-banner].animated-element {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

body:not(.no-js) .reveal {
  position: relative;
  overflow: hidden;
}

body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .image-wrap .enter-image-style {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .animating .enter-image-style {
  transform: scale(1);
  transition: 4s ease-out;
}

@keyframes scrollDown {
  0% {
    transform: translate(13.062px, 20.843px);
  }
  50% {
    transform: translate(13.062px, 9.843px);
  }
  100% {
    transform: translate(13.062px, 20.843px);
  }
}