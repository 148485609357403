#footer {
    padding-block: 131px 50px;
    position: relative;
    
    @media (max-width: 992px) {
        padding-block: 55px 92px;
    }
    .navigation, .redes {
        padding-top: 17px;
        
        @media (max-width: 992px) {
            padding-top: 0;
        }
    }
    .navigation {
        gap: 29px;

        a {
            color: $gray-900;
            font-size: 16px;
            font-weight: 700;
            line-height: 24.96px;
            text-decoration: none;
            transition: all .3s ease-in-out;

            &:hover {
                color: $green-accent-400;
            }
        }
    }
    .logo {
        @media (max-width: 992px) {
            margin-bottom: 19px;
        }
        p {
            color: #1E1E1E;
            font-size: 16px;
            font-weight: 500;
            line-height: 30px;

            @media (max-width: 992px) {
                position: absolute;
                bottom: 34px;
                left: 0;
                width: 100%;
                text-align: center;

                font-size: 14px;
                line-height: 30px;
            }
        }
        .weecom {
            @media (max-width: 992px) {
                position: absolute;
                bottom: 10px;
                left: 0;
                width: 100%;
                text-align: center;
            }
        }
    }
    .redes {
        gap: 37px;
        
        @media (max-width: 992px) {
            gap: 9px;
            flex-direction: column;
        }
        p {
            color: $gray-900;
            font-size: 16px;
            font-weight: 700;
            line-height: 24.96px;

            @media (max-width: 992px) {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}