@import '../general/color';

/*------------------------- Containers -----------------------*/

.termsfeed-com---nb .cc-nb-main-container{

}

.termsfeed-com---palette-dark.termsfeed-com---nb{
    background: #fff;
    background-color: #fff;
    box-shadow: 0px 3px 10px #1E1E1E;
}
.termsfeed-com---palette-light.termsfeed-com---nb{
    box-shadow: 0px 3px 10px #1E1E1E;
}
.termsfeed-com---palette-light.termsfeed-com---nb{
    background-color: white;
}


.termsfeed-com---pc-dialog {
    .cc-pc-head {
        background: #6F7500;
        color: $gray-900;
        border: #6F7500;

        .cc-pc-head-close {
            &:focus {
                border: 2px solid #fff;
            }
        }
    }
}
.termsfeed-com---palette-dark {
    .cc-cp-body,
    .cc-cp-body-tabs,
    .cc-cp-body-content,
    .cc-cp-foot {
        background: #6F7500 !important;
    }
    .cc-cp-body-tabs-item {
        background: #6F7500 !important;
        border: none !important;
        color: #fff !important;
        
        &[active=true] {
            background: $gray-900 !important;
        }
        &-link {
            color: #fff !important;

            &:focus {
                box-shadow: none !important;
            }
        }
    }
    .cc-cp-body-tabs {
        border-right: 1px solid #fff;

        @media (max-width: 600px) {
            border-right: none;
            border-bottom: 1px solid #fff;
        }
    }
    .cc-cp-foot {
        border-color: #fff !important;

        &-byline {
            a { 
                color: #fff !important;
                font-weight: bold;
            }
        }
        &-save {
            color: #6F7500;
            background: #fff;
            margin-right: 1rem !important;
            border: 1px solid #fff;            
        }
    }
}

/*-------------------------TEXTS -----------------------*/

.termsfeed-com---palette-dark .cc-nb-title {
    color: $gray-900;
}
.termsfeed-com---palette-dark .cc-nb-text {
    color: $gray-900;
}

/*------------------------ BUTTONS -----------------------*/
.termsfeed-com---palette-dark .cc-nb-okagree{
    background: #6F7500;
    color: #fff;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border-radius: 10px 0 0 0 !important;
}
.termsfeed-com---palette-dark .cc-nb-reject{
    color: #fff;
    background: #6F7500;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border-radius: 10px 0 0 0 !important;
}
.termsfeed-com---palette-dark .cc-nb-changep{
    color: #fff;
    background: #6F7500;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border-radius: 10px 0 0 0 !important;
}
.termsfeed-com---palette-dark .cc-nb-okagree{
    color: #fff;
    background: #6F7500;
    margin-right: 1rem !important;
    transition: background-color 1s;
    border-radius: 10px 0 0 0 !important;
}
.termsfeed-com---nb-simple{
    bottom: 2vh;
    transition: 0.4s;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    &::after{
    content: "";
    height: 3px;
    background-color: #6F7500;
    position: absolute;
    left: 0;
    bottom: 0;
    }
}
// Accept
.termsfeed-com---palette-light .cc-nb-okagree{
    border-radius: 10px 0 0 0 !important;
    padding: 10px 20px !important;
}
// Decline
.termsfeed-com---palette-light .cc-nb-reject{
    border-radius: 10px 0 0 0 !important;
    padding: 10px 20px !important;
}
// Config
.termsfeed-com---palette-light .cc-nb-changep{
    border-radius: 10px 0 0 0 !important;
    padding: 10px 20px !important;
}
