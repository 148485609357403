///////////////////////////////////
////////// FONT-FACE //////////////
///////////////////////////////////

//SE FOR GOOGLEFONTES COLOCAR NO APP.BLADE.PHP

@font-face {
    font-family: "Autography";
    src: url('../fonts/Autography.otf') format('opentype');
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-BoldOblique.otf') format('opentype');
    font-weight: bold;
    font-style: oblique;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-DemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-DemiBoldOblique.otf') format('opentype');
    font-weight: 600;
    font-style: oblique;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-ExtraBold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-ExtraBoldOblique.otf') format('opentype');
    font-weight: 800;
    font-style: oblique;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-Heavy.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-HeavyOblique.otf') format('opentype');
    font-weight: 900;
    font-style: oblique;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-LightOblique.otf') format('opentype');
    font-weight: 300;
    font-style: oblique;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-MediumOblique.otf') format('opentype');
    font-weight: 500;
    font-style: oblique;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-RegularOblique.otf') format('opentype');
    font-weight: 400;
    font-style: oblique;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Visby';
    src: url('../fonts/VisbyCF/VisbyCF-ThinOblique.otf') format('opentype');
    font-weight: 100;
    font-style: oblique;
    font-display: swap;
}

* {
    font-family: "Visby";
}

$thin: 100;
$extra-light: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$black: 900;

html {
    // font-size: 62.5%;

    // 12px = 1.2rem
    // 20px = 2.0rem
    // 35px = 3.5rem
    // 46px = 4.6rem
	// 91px = 9.1rem
}

@mixin fontTemplate($size, $height, $color, $weight, $family, $align: start) {
    font-size: $size;
    line-height: $height;
    color: $color;
    font-weight: $weight;
    font-family: $family;
	text-align: $align;
}