@import '../general/color';

.navbar {
    background-color: #fff;
    padding-block: 21px 20px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;

    @media (max-width: 992px) {
        box-shadow: 0px 4px 20px 0px #00000026;
        padding-block: 24px 19px;
    }
    &-brand {
        img {
            max-width: 302px;
            max-height: 58px;

            @media (max-width: 1200px) {
                max-width: 250px;
            }
            @media (max-width: 992px) {
                max-width: 180px;
            }
        }
    }
    &-toggler {
        padding: 7px 8px 3px 10px;
        background-color: $green-accent-300;
        border-radius: 20px 0 0 0;
        border: none;

        &:hover, &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    &-nav {
        gap: 63px;

        @media (max-width: 1400px) {
            gap: 45px;
        }
        @media (max-width: 1200px) {
            gap: 40px;
        }
        @media (max-width: 992px) {
            gap: 30px;
        }
        .nav-link {
            position: relative;
            color: $gray-900;
            font-size: 16px;
            font-weight: 700;
            line-height: 24.96px;            
            padding: 0 !important;

            @media (max-width: 992px) {
                color: $green-accent-400;
                line-height: 18px;
            }
            &:hover, &.active {
                @media (max-width: 992px) {
                    color: $gray-900;
                }
                &::before {
                    opacity: 1;
                }
            }
            &::before {
                content: '';
                width: 15px;
                aspect-ratio: 1;
                background-color: $green-accent-400;
                border-radius: 50%;
                transition: all 0.4s ease;
    
                position: absolute;
                top: calc(50% - (15px / 2));
                right: calc(100% + 18px);
    
                opacity: 0;

                @media (max-width: 1400px) {
                    right: calc(100% + 10px);
                }
            }
        }
    }
    .main-btn {
        background-color: #6F7500;
        
        @media (max-width: 1200px) {
            padding-inline: 32px;
        }
        @media (max-width: 992px) {
            margin-top: 46px;
            max-width: 100%;
        }
    }

    .offcanvas {
        --bs-offcanvas-width: 100%;

        &-header {
            padding-block: 21px 20px;
            padding-inline: 0;
            box-shadow: 0px 4px 20px 0px #00000026;
        }
        &-body {
            padding: 50px 38px;
        }
        &-footer {
            position: absolute;
            width: 100%;
            bottom: 0;
            right: 0;

            gap: 36px;

            background-color: $green-accent-300;
            padding: 23px 38px;

            p {
                color: #fff;
                font-size: 17px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: -0.05em;
            }
        }
    }
}