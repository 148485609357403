@import '../general/color';

.main-btn {
    padding: 14px 54px 11px;
    border-radius: 20px 0 0 0;
    background-color: $green-accent-400;

    color: #fff;
    font-family: "Visby";
    font-size: 18px;
    font-weight: 800;
    line-height: 28.08px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all .3s ease-in-out;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;

    &:hover {
        translate: 0 -3px;
        color: #fff;
    }
}