@import '../general/color';

.scroll {
    width: 100%;
}

.text-marquee {
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    overflow-y: clip;
    z-index: 3;
    .text-single {
        position: relative;
        white-space: nowrap;
        padding: 0;
        margin: 0;
        will-change: transform;
        overflow: hidden;

        &.img-wrapper {
            .text {
                margin-right: 80px;

                @media (max-width: 992px) {
                    margin-right: 57px;
                }
                img {
                    max-width: 248px;
                    object-fit: contain;

                    @media (max-width: 992px) {
                        max-width: 155px;
                    }
                }
            }
        }
    }

    .text {
        padding: 0;
        display: inline-block;
        margin-right: 100px;

        @media (max-width: 992px) {
            margin-right: 60px;
        }
        &, h3 {
            color: $green-accent-300;
            font-size: 90px;
            font-weight: 600;
            line-height: 100px;
            letter-spacing: -0.02em;

            @media (max-width: 992px) {
                font-size: 50px;
                line-height: 80px;
            }
        }
        i > strong {
            color: $gray-900;
            font-size: 220px;
            line-height: 100px;

            @media (max-width: 992px) {
                font-size: 80px;
                line-height: 1;
            }
        }
    }

    @media (max-width: 599px) {
        .text {
            font-size: 5rem;
            line-height: 1.35;
        }
    }
}
